import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { KycDocumento } from "src/app/panel-inversor/perfil/_models/kyc-documento";
import { BaseService } from "src/app/_services/base.service";
import { InformacionBasica } from "src/app/panel-inversor/_models/informacion-basica";

@Injectable({
  providedIn: "root"
})
export class InformacionBasicaService extends BaseService
{

  constructor(httpClient: HttpClient)
  {
    super(httpClient, "SegoFinance/InformacionBasica", "v2");
  }

  get(): Observable<InformacionBasica>
  {
    return this._http.get<InformacionBasica>(`${this._apiCoreUrl}v1/InformacionBasica`)
  }

  checkKYCLevel(): Observable<any>
  {
    return this._http.get<any>(`${this._apiCoreUrl}v1/InformacionBasica/CheckKYCLevel`)
  }
  ultimoDocumentoKyc(): Observable<KycDocumento>
  {
    return this._http.get<KycDocumento>(`${this._apiCoreUrl}v1/InformacionBasica/UltimoDocumentoKYC`)
  }
}
